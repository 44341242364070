import { create } from 'zustand';
import { Property } from '../utils/interfaces';

interface PropertyStore {
  properties: Property[] | null;
  setProperties: (properties: Property[]) => void;
  isPropertyLoading: boolean;
  setLoading: (isPropertyLoading: boolean) => void;
}

export const usePropertyStore = create<PropertyStore>((set) => ({
  properties: null,
  isPropertyLoading: false,
  setProperties: (properties: Property[]) => set({ properties }),
  setLoading: (isPropertyLoading: boolean) => set({ isPropertyLoading }),
}));
