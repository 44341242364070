import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useAuth } from '../helper/AuthContext';
import { useTranslation } from 'react-i18next';
import { UserRoleType } from '../types/types';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

const Navbar = () => {
  const { isAuth, logout, userRoleType } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // 🚀 Redirect authenticated users to "/overview"
  useEffect(() => {
    if (
      isAuth &&
      window.location.pathname === '/' &&
      (!window.location.pathname.includes('verify') ||
        !window.location.pathname.includes('reset-password'))
    ) {
      navigate('/overview');
    }
  }, [isAuth, navigate]);

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);
  const handleLogout = async () => {
    await logout();
    handleMenuClose();
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const navLinks = [
    { path: '/', label: t('home') },
    { path: '/about', label: t('aboutUs') },
  ];

  const authLinks = [
    {
      path: '/overview',
      label: t('overview'),
      roles: [UserRoleType.Landlord, UserRoleType.Caretaker],
    },
    {
      path: '/properties',
      label: t('buildings'),
      roles: [UserRoleType.Landlord, UserRoleType.Caretaker],
    },
    {
      path: '/tenants',
      label: t('tenants'),
      roles: [UserRoleType.Landlord, UserRoleType.Caretaker],
    },
    {
      path: '/subscription',
      label: t('subscription_view'),
      roles: [UserRoleType.Landlord],
    },
    {
      path: '/building-requests',
      label: t('requests'),
      roles: [UserRoleType.Caretaker],
    },
  ];

  const buttonStyle = {
    mx: 1,
    color: 'white',
    border: '2px solid transparent',
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: 'white',
      backgroundColor: '##E93583',
    },
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(90deg,#F09950 0%,#7c5350 100%)',
        color: 'white',
      }}
      elevation={3}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ fontWeight: 'bold', textDecoration: 'none', color: 'white' }}
        >
          {t('leaseSphere')}
        </Typography>

        {isMobile ? (
          // Mobile Menu: Hamburger Icon
          <>
            <IconButton color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
              >
                <List>
                  {(!isAuth
                    ? [...navLinks, { path: '/login', label: t('getStarted') }]
                    : authLinks.filter((link) =>
                        link.roles.includes(userRoleType as UserRoleType)
                      )
                  ).map(({ path, label }) => (
                    <ListItem key={path} component={Link} to={path}>
                      <ListItemText primary={label} />
                    </ListItem>
                  ))}

                  {isAuth && (
                    <>
                      <ListItem component={Link} to="/profile">
                        <ListItemText primary={t('profile')} />
                      </ListItem>
                      {userRoleType === UserRoleType.Landlord && (
                        <ListItem component={Link} to="/access-request">
                          <ListItemText primary={t('caretakerRequest')} />
                        </ListItem>
                      )}
                      <ListItem
                        component="li"
                        onClick={handleLogout}
                        sx={{ cursor: 'pointer' }}
                      >
                        <ListItemText primary={t('logout')} />
                      </ListItem>
                    </>
                  )}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          // Desktop Menu: Regular Buttons
          <Box>
            {!isAuth ? (
              <>
                {navLinks.map(({ path, label }) => (
                  <Button
                    key={path}
                    component={Link}
                    to={path}
                    sx={buttonStyle}
                  >
                    {label}
                  </Button>
                ))}
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: 'white',
                    color: '#F09950',
                    '&:hover': { backgroundColor: '#FFEBEE' },
                  }}
                  component={Link}
                  to="/login"
                >
                  {t('getStarted')}
                </Button>
              </>
            ) : (
              <>
                {userRoleType === UserRoleType.Tenant ? (
                  <Button
                    color="inherit"
                    onClick={handleLogout}
                    sx={buttonStyle}
                  >
                    {t('logout')}
                  </Button>
                ) : (
                  <>
                    {authLinks
                      .filter((link) =>
                        link.roles.includes(userRoleType as UserRoleType)
                      )
                      .map(({ path, label }) => (
                        <Button
                          key={path}
                          component={Link}
                          to={path}
                          sx={buttonStyle}
                        >
                          {label}
                        </Button>
                      ))}
                    <Button
                      color="inherit"
                      onClick={handleAccountClick}
                      sx={buttonStyle}
                    >
                      {t('account')}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        component={Link}
                        to="/profile"
                        onClick={handleMenuClose}
                      >
                        {t('profile')}
                      </MenuItem>
                      {userRoleType === UserRoleType.Landlord && (
                        <MenuItem
                          component={Link}
                          to="/access-request"
                          onClick={handleMenuClose}
                        >
                          {t('caretakerRequest')}
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                    </Menu>
                  </>
                )}
              </>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
