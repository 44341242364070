import { useEffect } from 'react';
import {
  useQuery,
  keepPreviousData,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Tenant } from '../utils/interfaces';
import { calculateStatus } from '../utils/dateUtils';
import { useTenantStore } from '../stores/TenantStore';
import { t } from 'i18next';

const fetchTenantsFromApi = async (): Promise<Tenant[]> => {
  const response = await api.getTenants();
  const updatedTenants = response.map((tenant: Tenant) => {
    const status = calculateStatus(tenant.EndDate);
    tenant.Status = t(status.toLowerCase());
    return tenant;
  });

  updatedTenants.sort((a: Tenant, b: Tenant) => {
    const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
    const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
    return nameA.localeCompare(nameB);
  });

  const encryptedData = encryptData(JSON.stringify(updatedTenants));
  await saveData('tenants', encryptedData);

  return updatedTenants;
};

const loadTenantsFromIndexedDB = async (): Promise<Tenant[] | null> => {
  const savedTenants = await getData('tenants');

  if (savedTenants && savedTenants.value.length > 0) {
    const decryptedData = decryptData(savedTenants.value);
    return JSON.parse(decryptedData);
  } else {
    return null;
  }
};

export const useFetchTenants = () => {
  const { setTenants, setLoading } = useTenantStore();
  const queryClient = useQueryClient();

  const fetchTenants = async (tenantsQuery: any): Promise<Tenant[]> => {
    const tenantsFromDB = await loadTenantsFromIndexedDB();

    if (tenantsFromDB && !tenantsQuery.isStale) {
      setTenants(tenantsFromDB);
      return tenantsFromDB;
    }

    const tenantsFromApi = await fetchTenantsFromApi();
    setTenants(tenantsFromApi);
    return tenantsFromApi;
  };

  const tenantsQuery = useQuery<Tenant[], Error>({
    queryKey: ['tenants'],
    queryFn: async (queryFnContext) => {
      setLoading(true);
      try {
        return await fetchTenants(queryFnContext);
      } finally {
        setLoading(false);
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    staleTime: 1000 * 60 * 30,
    gcTime: 1000 * 60 * 50,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (tenantsQuery.isSuccess && tenantsQuery.isStale) {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });
    }
  }, [tenantsQuery.isSuccess, tenantsQuery.isStale, queryClient]);

  return tenantsQuery;
};
