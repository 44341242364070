import { useEffect } from 'react';
import {
  useQuery,
  keepPreviousData,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Message } from '../utils/interfaces';
import { useMessageStore } from '../stores/MessageStore';

const errorMessages = new ErrorMessages();

// Fetch messages from IndexedDB
const loadMessagesFromIndexedDB = async (): Promise<Message[] | null> => {
  try {
    const savedMessages = await getData('messages');
    if (savedMessages && savedMessages.value.length > 0) {
      const decryptedData = decryptData(savedMessages.value);
      return JSON.parse(decryptedData) as Message[];
    }
    return null;
  } catch (error) {
    toast.error('Failed to load messages from IndexedDB');
    return null;
  }
};

// Fetch messages from API and save to IndexedDB
const fetchMessagesFromApi = async (): Promise<Message[]> => {
  try {
    const response = await api.getMessages();

    if (response && Array.isArray(response)) {
      // Encrypt and save the messages data to IndexedDB
      const encryptedData = encryptData(JSON.stringify(response));
      await saveData('messages', encryptedData);

      return response as Message[];
    }
    throw new Error('Invalid API response');
  } catch (error) {
    ErrorHandler.handleError(error, errorMessages, toast);
    throw error; // Important to propagate error for React Query
  }
};

// React Query hook to fetch messages, loading from IndexedDB first
export const useFetchMessages = () => {
  const { setMessages, setLoading } = useMessageStore();
  const queryClient = useQueryClient();

  const fetchMessages = async (messagesQuery: any): Promise<Message[]> => {
    const messagesFromDB = await loadMessagesFromIndexedDB();

    if (messagesFromDB && !messagesQuery.isStale) {
      setMessages(messagesFromDB);
      return messagesFromDB; // Return messages from IndexedDB
    } else {
      const messagesFromApi = await fetchMessagesFromApi();
      setMessages(messagesFromApi);
      return messagesFromApi;
    }
  };

  const messagesQuery = useQuery<Message[]>({
    queryKey: ['messages'],
    queryFn: async (queryFnContext) => {
      setLoading(true);
      try {
        return await fetchMessages(queryFnContext);
      } finally {
        setLoading(false);
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (messagesQuery.isSuccess && messagesQuery.isStale) {
      queryClient.invalidateQueries({ queryKey: ['messages'] });
    }
  }, [messagesQuery.isSuccess, messagesQuery.isStale, queryClient]);

  return messagesQuery;
};
