import { useEffect } from 'react';
import {
  useQuery,
  keepPreviousData,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Property } from '../utils/interfaces';
import { usePropertyStore } from './../stores/PropertyStore';

const fetchPropertiesFromApi = async (): Promise<Property[]> => {
  const response = await api.getPropertiesByUser();
  const sortedProperties = response.sort((a: Property, b: Property) =>
    a.PropertyName.localeCompare(b.PropertyName)
  ); // Sort alphabetically by 'PropertyName'

  const encryptedData = encryptData(JSON.stringify(sortedProperties));
  await saveData('properties', encryptedData);

  return sortedProperties;
};

const loadPropertiesFromIndexedDB = async (): Promise<Property[] | null> => {
  const savedProperties = await getData('properties');

  if (savedProperties && savedProperties.value.length > 0) {
    const decryptedData = decryptData(savedProperties.value);
    return JSON.parse(decryptedData);
  } else {
    return null;
  }
};

export const useFetchProperties = () => {
  const { setProperties, setLoading } = usePropertyStore();
  const queryClient = useQueryClient();

  const fetchProperties = async (propertiesQuery: any): Promise<Property[]> => {
    const propertiesFromDB = await loadPropertiesFromIndexedDB();

    if (propertiesFromDB && !propertiesQuery.isStale) {
      setProperties(propertiesFromDB);
      return propertiesFromDB;
    }

    const propertiesFromApi = await fetchPropertiesFromApi();
    setProperties(propertiesFromApi);
    return propertiesFromApi;
  };

  const propertiesQuery = useQuery<Property[]>({
    queryKey: ['properties'],
    queryFn: async (queryFnContext) => {
      setLoading(true);

      try {
        return await fetchProperties(queryFnContext);
      } finally {
        setLoading(false);
      }
    },
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (propertiesQuery.isSuccess && propertiesQuery.isStale) {
      queryClient.invalidateQueries({ queryKey: ['properties'] });
    }
  }, [propertiesQuery.isSuccess, propertiesQuery.isStale, queryClient]);

  return propertiesQuery;
};
