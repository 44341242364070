import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { useRoomStore } from '../stores/RoomStore';
import { RoomResponse } from '../utils/interfaces';

const fetchRoomsFromApi = async (propertyId: string): Promise<RoomResponse> => {
  const response = await api.getRoomsByID(propertyId);

  const encryptedData = encryptData(JSON.stringify(response));
  await saveData(`rooms_${propertyId}`, encryptedData);

  return response;
};

const loadRoomsFromIndexedDB = async (
  propertyId: string
): Promise<RoomResponse | null> => {
  const savedRooms = await getData(`rooms_${propertyId}`);
  if (savedRooms?.value) {
    const decryptedData = decryptData(savedRooms.value);
    return JSON.parse(decryptedData);
  }
  return null;
};

export const useFetchRooms = (propertyId: string) => {
  const { setRooms, setLoading } = useRoomStore();

  return useQuery<RoomResponse>({
    queryKey: ['rooms', propertyId],
    queryFn: async () => {
      setLoading(true);
      try {
        const roomsFromDB = await loadRoomsFromIndexedDB(propertyId);
        if (roomsFromDB) {
          setRooms(roomsFromDB.rooms);
          return roomsFromDB;
        }

        const roomsFromApi = await fetchRoomsFromApi(propertyId);
        setRooms(roomsFromApi.rooms);
        return roomsFromApi;
      } finally {
        setLoading(false);
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    placeholderData: keepPreviousData,
  });
};
