import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { SelectChangeEvent } from '@mui/material/Select';
import { Subscription } from '../utils/interfaces';
import { toast } from 'react-toastify';
import { useSubscriptions } from '../hooks/subscription.hooks';
import { ErrorHandler, ErrorMessages } from '../helper/ErrorMessages';
import { useFetchUser } from '../hooks/user.hooks';
import { useTranslation } from 'react-i18next';
import { api } from '../utils/api';
import { deleteKeyStore } from '../utils/indexedDB';
import { PaystackButton } from 'react-paystack';
import { PayStackProps } from '../utils/paystack';
import { useAuth } from '../helper/AuthContext';
import { SubscriptionType } from '../types/types';
import LoadingSpinner from '../helper/LoadingSpinner';

const SubscriptionView = () => {
  const { t } = useTranslation();
  const errorMessages = new ErrorMessages();
  const { data: user, isError: isUserError, error: userError } = useFetchUser();
  const {
    data: subscriptions,
    isError: isSubscriptionsError,
    error: subscriptionsError,
    isLoading: isSubscriptionsLoading,
    refetch,
  } = useSubscriptions();

  const [currentSubscription, setCurrentSubscription] =
    useState<Subscription | null>(null);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [temporarySubscription, setTemporarySubscription] =
    useState<Subscription | null>(null);
  const [totalAmount, setTotalAmount] = useState<string>('');
  const [selectedDuration, setSelectedDuration] = useState<number | string>('');
  const [showDurationDropdown, setShowDurationDropdown] = useState(false);
  const [showUpgradeCard, setShowUpgradeCard] = useState(true);
  const [openPackageModal, setOpenPackageModal] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState<string[]>([]);
  const { subscriptionType } = useAuth();

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
    if (isSubscriptionsError) {
      ErrorHandler.handleError(subscriptionsError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserError, userError, isSubscriptionsError, subscriptionsError]);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      const activeSubscription =
        subscriptions.find((sub) => sub.IsActive) || subscriptions[0];
      setCurrentSubscription(activeSubscription);
    }
  }, [subscriptions]);

  const handleUpgrade = (plan: string) => {
    // Hide the subscription plans and show the duration dropdown
    setShowUpgradeCard(false);

    // Ensure Duration is a number, and set it to a default value if not set yet
    setTemporarySubscription({
      ...currentSubscription,
      SubscriptionType: plan,
      Duration: selectedDuration
        ? parseInt(selectedDuration.toString(), 10)
        : 0,
      PromoCode: promoCode || '',
      PaymentReference: `REF_${new Date().getTime()}}`,
    });
    setShowDurationDropdown(true);

    const selectedPlan = subscriptionPlans.find((p) => p.type === plan);
    if (selectedPlan) {
      // Pass selectedPackages as an argument
      calculateTotalAmount(selectedPlan.price, selectedDuration);
    }
  };

  const handleApplyPromoCode = async () => {
    if (promoCode) {
      try {
        const subCreateData: Subscription = {
          SubscriptionType: 'Premium',
          Duration: 1,
          PromoCode: promoCode,
          PaymentReference: `PromoCode_${new Date().getTime()}`,
        };
        await api.createSubscription(subCreateData);
        await deleteKeyStore('profileDetails');
        toast.success(t('success'));
        refetch();
        handleCancelPromoCode();
      } catch (error: any) {
        ErrorHandler.handleError(error, errorMessages, toast);
      }
    } else {
      toast.error(t('subscription.pleaseEnterValidPromoCode'));
    }
  };

  const handleTogglePromoCode = () => {
    setShowPromoCode(!showPromoCode);
    setShowDurationDropdown(false);
    setShowUpgradeCard(true);
  };

  const handleCancelPromoCode = () => {
    setShowPromoCode(false);
    setShowDurationDropdown(false);
    setPromoCode('');
  };

  const handleCancelDuration = () => {
    setShowDurationDropdown(false);
    setShowPromoCode(false);
    setSelectedDuration('');
    setShowUpgradeCard(true);
    setTotalAmount('');
    setSelectedPackages([]);
  };

  const handleDurationChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedDuration(event.target.value);

    if (temporarySubscription && event.target.value) {
      const selectedPlan = subscriptionPlans.find(
        (plan) => plan.type === temporarySubscription.SubscriptionType
      );

      if (temporarySubscription.SubscriptionType === SubscriptionType.Free) {
        setSelectedDuration('');
        toast.warning(t('subscription.free_warning'));
      }

      if (selectedPlan) {
        calculateTotalAmount(selectedPlan.price, event.target.value);
      }
    }
  };

  const handleConfirmPackages = () => {
    handleClosePackageModal();
  };

  const handlePaymentSuccess = async (response: any) => {
    // Check if the transaction is successful
    if (response.status === 'success') {
      try {
        const subCreateData: Subscription = {
          ...(temporarySubscription as Subscription),
          UserID: user?.UserID,
          AdditionalPackages: selectedPackages,
          Amount: totalAmount,
          Duration: selectedDuration as number,
          PaymentReference: response.reference,
          Platform: true,
        };

        await api.createSubscription(subCreateData);
        await deleteKeyStore('profileDetails');
        toast.success(t('success'));
        refetch();
        handleCancelDuration();
        setTemporarySubscription(null);
      } catch (error: any) {
        ErrorHandler.handleError(error, errorMessages, toast);
      }
    } else {
      toast.error('Transaction failed, please try again!');
    }
  };

  const handleClose = () => {
    toast.info(t('payment_not_completed'));
  };

  const calculateTotalAmount = (
    planPrice: string,
    duration: number | string
  ) => {
    // Remove non-numeric characters (₦ and commas) and parse the price as a number
    const price = parseInt(
      planPrice.replace('₦', '').replace(',', '').trim(),
      10
    );

    // Ensure the price is a valid number
    if (isNaN(price) || isNaN(Number(duration))) {
      setTotalAmount('₦ 0'); // Default to 0 if the price or duration is invalid
    } else {
      let total = price * (typeof duration === 'number' ? duration : 0);

      setTotalAmount(`₦ ${total.toLocaleString()}`);
    }
  };

  const handleOpenPackageModal = () => {
    setOpenPackageModal(true);
  };

  const handleClosePackageModal = () => {
    setOpenPackageModal(false);
  };

  const handlePackageSelection = (packageName: string) => {
    setSelectedPackages((prevSelected) => {
      const updatedSelected = prevSelected.includes(packageName)
        ? prevSelected.filter((pkg) => pkg !== packageName)
        : [...prevSelected, packageName];

      return updatedSelected;
    });
  };

  const handleManageSubscriptionModal = () => {
    setShowDurationDropdown(true);
    setShowPromoCode(false);
    setShowUpgradeCard(false);
    setTemporarySubscription({
      ...currentSubscription,
      SubscriptionType: currentSubscription?.SubscriptionType as string,
      Duration: selectedDuration
        ? parseInt(selectedDuration.toString(), 10)
        : 0,
      PromoCode: promoCode || '',
      PaymentReference: `REF_${new Date().getTime()}}`,
    });
  };

  const packageOptions = [
    {
      name: t('subscription.packages.cleaning.name'),
      description: t('subscription.packages.cleaning.description'),
    },
    {
      name: t('subscription.packages.maintenance.name'),
      description: t('subscription.packages.maintenance.description'),
    },
    {
      name: t('subscription.packages.advocacy.name'),
      description: t('subscription.packages.advocacy.description'),
      disabled: true,
    },
    {
      name: t('subscription.packages.routine.name'),
      description: t('subscription.packages.routine.description'),
    },
  ];

  const subscriptionPlans = [
    {
      type: t('subscription.plans.basic'),
      price: '₦ 8,000 / ' + t('subscription.month'),
      properties: 3,
      units: 30,
    },
    {
      type: t('subscription.plans.standard'),
      price: '₦ 14,000 / ' + t('subscription.month'),
      properties: 6,
      units: 80,
      recommended: true,
    },
    {
      type: t('subscription.plans.premium'),
      price: '₦ 24,000 / ' + t('subscription.month'),
      properties: 'Unlimited',
      units: 'Unlimited',
    },
  ];

  const componentProps: PayStackProps = {
    email: user?.Email || '',
    amount:
      parseInt(totalAmount.replace('₦', '').replace(',', '').trim(), 10) * 100,
    reference: `REF_${new Date().getTime()}`,
    currency: 'NGN',
    text: t('subscription.pay'),
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || '',
    metadata: {
      custom_fields: [
        {
          display_name: 'Customer Name',
          variable_name: 'customer_name',
          value: user?.Fullname || user?.Username,
        },
        {
          display_name: 'Subscription Type',
          variable_name: 'subscription_type',
          value: temporarySubscription?.SubscriptionType,
        },
        {
          display_name: 'Duration',
          variable_name: 'duration',
          value: selectedDuration,
        },
        { display_name: 'Price', variable_name: 'price', value: totalAmount },
        {
          display_name: 'Country',
          variable_name: 'country',
          value: user?.Country || 'Not provided',
        },
        {
          display_name: 'City',
          variable_name: 'city',
          value: user?.City || 'Not provided',
        },
        {
          display_name: 'Additional Services',
          variable_name: 'additional_services',
          value: selectedPackages.join(', '),
        },
      ],
    },
    onSuccess: handlePaymentSuccess,
    onClose: handleClose,
  };

  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 3 }}>
      <Typography variant="h3" fontWeight="bold">
        {t('subscription.subscriptionPlans')}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('subscription.choosePlan')}
      </Typography>

      {isSubscriptionsLoading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ mb: 8 }}>
          {showPromoCode ? (
            <Box
              sx={{
                textAlign: 'left',
                maxWidth: 600,
                margin: 'auto',
                padding: 3,
                border: '1px solid #ddd',
                borderRadius: 2,
                backgroundColor: '#f9f9f9',
                boxShadow: 2,
                mb: 8,
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                {t('subscription.enterPromoCode')}
              </Typography>
              <TextField
                label={t('subscription.promoCode')}
                variant="outlined"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: '#EC675D',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#FF9B5D',
                    },
                  }}
                  onClick={handleApplyPromoCode}
                  fullWidth
                >
                  {t('subscription.applyPromoCode')}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FF6666',
                      color: 'white',
                    },
                  }}
                  onClick={handleCancelPromoCode}
                  fullWidth
                >
                  {t('subscription.cancel')}
                </Button>
              </Box>
            </Box>
          ) : (
            showUpgradeCard && (
              <Grid container spacing={3} justifyContent="center">
                {subscriptionPlans.map((plan, index) => {
                  const isCurrent =
                    currentSubscription?.SubscriptionType === plan.type;
                  const isPremium =
                    plan.type === t('subscription.plans.premium');
                  const isStandard =
                    plan.type === t('subscription.plans.standard');

                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card
                        sx={{
                          border: isCurrent
                            ? '2px solid #1976d2'
                            : '1px solid #ddd',
                          boxShadow: 3,
                          borderRadius: 3,
                          transition:
                            'transform 0.3s ease-in-out, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: 6,
                          },
                          position: 'relative',
                          height: '100%', // Ensures all cards take up the full height
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {plan.recommended && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 1,
                              backgroundColor: 'red',
                              color: 'white',
                              padding: '3px 7px',
                              borderRadius: 1,
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                            }}
                          >
                            Recommended
                          </Box>
                        )}
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            sx={{ mb: 1 }}
                          >
                            {plan.type}
                          </Typography>
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{ mb: 2 }}
                          >
                            {plan.price}
                          </Typography>
                          <Typography variant="body2">
                            {t('subscription.properties')}: {plan.properties}
                          </Typography>
                          <Typography variant="body2">
                            {t('subscription.unitsPerProperty')}: {plan.units}
                          </Typography>
                          {(isPremium || isStandard) && (
                            <Typography variant="body2">
                              {t('subscription.chat')}
                            </Typography>
                          )}
                          {/* Add empty space for non-premium plans to match height */}
                          {!isPremium && <Box sx={{ flexGrow: 1 }} />}

                          {isPremium && (
                            <Typography variant="body2">
                              {t('subscription.analysis_insight')}
                            </Typography>
                          )}
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                          <Button
                            variant={isCurrent ? 'contained' : 'outlined'}
                            color="primary"
                            disabled={isCurrent}
                            sx={{
                              '&:hover': {
                                backgroundColor: '#FF9B5D',
                                color: 'white',
                              },
                            }}
                            onClick={() => handleUpgrade(plan.type)}
                          >
                            {isCurrent
                              ? t('subscription.currentPlan')
                              : t('subscription.upgrade')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )
          )}
        </Box>
      )}

      {showDurationDropdown && (
        <Box
          sx={{
            textAlign: 'left',
            maxWidth: 600,
            margin: 'auto',
            padding: 3,
            border: '1px solid #ddd',
            borderRadius: 2,
            backgroundColor: '#f9f9f9',
            boxShadow: 2,
            mb: 8,
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {t('subscription.selectSubscriptionDuration')}
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t('subscription.duration')}</InputLabel>
            <Select
              value={selectedDuration}
              onChange={handleDurationChange}
              label={t('subscription.duration')}
            >
              <MenuItem value={3}>3 {t('subscription.months')}</MenuItem>
              <MenuItem value={6}>6 {t('subscription.months')}</MenuItem>
              <MenuItem value={12}>12 {t('subscription.months')}</MenuItem>
            </Select>
          </FormControl>

          {totalAmount && (
            <Box
              sx={{
                mt: 1,
                p: 1.5,
                borderRadius: 1,
                backgroundColor: '#EC675D',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 3,
                minWidth: '180px',
                maxWidth: '250px',
                mx: 'auto',
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('subscription.totalAmount')}: {totalAmount}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                '&:hover': {
                  backgroundColor: '#FF6666',
                  color: 'white',
                },
              }}
              onClick={handleCancelDuration}
            >
              {t('subscription.cancel')}
            </Button>
            {/* PaystackButton gets triggered here if the duration is valid */}
            <div style={{ textAlign: 'center' }}>
              <Tooltip
                title={!selectedDuration ? t('selectDuration') : ''}
                arrow
              >
                <span>
                  <PaystackButton
                    {...componentProps}
                    className={`paystack-button ${
                      !selectedDuration ? 'disabled' : ''
                    }`}
                    disabled={!selectedDuration}
                  />
                </span>
              </Tooltip>

              <style>
                {`
                  .paystack-button {
                    background-color: #EC675D !important; /* Match background color */
                    color: #fff !important; /* White text */
                    padding: 8px 16px !important;
                    border-radius: 4px !important;
                    border: none !important;
                    width: 100px !important;
                    height: 50px !important;
                    cursor: pointer !important;
                    display: inline-block !important;
                    transition: background-color 0.3s ease-in-out !important;
                  }

                  .paystack-button:hover {
                    background-color: #FF9B5D !important; /* Match hover color */
                  }

                  .paystack-button.disabled,
                  .paystack-button:disabled {
                    background-color: #b0b0b0 !important;
                    cursor: not-allowed !important;
                  }
                `}
              </style>
            </div>

            {selectedDuration && (
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  '&:hover': {
                    backgroundColor: '#FF9B5D',
                    color: 'white',
                  },
                }}
                onClick={handleOpenPackageModal}
              >
                {t('subscription.additionalPackages')}
              </Button>
            )}
          </Box>
        </Box>
      )}

      {currentSubscription && (
        <Box
          sx={{
            textAlign: 'left',
            maxWidth: 600,
            margin: 'auto',
            padding: 3,
            border: '1px solid #ddd',
            borderRadius: 2,
            backgroundColor: '#FFE6D5',
            boxShadow: 2,
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {t('subscription.currentPlan')}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <strong>{t('subscription.plan')}:</strong>{' '}
            {currentSubscription.SubscriptionType}
          </Typography>
          {subscriptionType !== SubscriptionType.Free &&
            currentSubscription.EndDate && (
              <Typography>
                <strong>{t('subscription.endDate')}:</strong>{' '}
                {new Date(currentSubscription.EndDate).toLocaleDateString()}
              </Typography>
            )}
          <Typography>
            <strong>{t('subscription.status')}:</strong>{' '}
            {currentSubscription.IsActive
              ? t('subscription.active')
              : t('subscription.expired')}
          </Typography>

          <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                '&:hover': {
                  backgroundColor: '#FF9B5D',
                  color: '#fff',
                },
              }}
              onClick={() => {
                handleManageSubscriptionModal();
              }}
            >
              {t('subscription.manageSubscription')}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              sx={{
                '&:hover': {
                  backgroundColor: '#FF9B5D',
                  color: '#fff',
                },
              }}
              onClick={handleTogglePromoCode}
            >
              {t('subscription.applyCode')}
            </Button>
          </Box>
        </Box>
      )}

      {/* Package Modal */}
      <Dialog open={openPackageModal} onClose={handleClosePackageModal}>
        <DialogTitle>{t('subscription.selectAdditionalPackages')}</DialogTitle>
        <DialogContent>
          {packageOptions.map((pkg) => (
            <FormControlLabel
              key={pkg.name}
              control={
                <Checkbox
                  checked={selectedPackages.includes(pkg.name)}
                  onChange={() => handlePackageSelection(pkg.name)}
                  disabled={pkg.disabled}
                />
              }
              label={`${pkg.name}`}
              sx={{ mb: 2 }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePackageModal}
            color="secondary"
            sx={{
              '&:hover': {
                backgroundColor: '#FF6666',
                color: 'white',
              },
            }}
          >
            {t('subscription.cancel')}
          </Button>
          <Button onClick={handleConfirmPackages} color="primary">
            {t('subscription.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriptionView;
