import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Language from '@mui/icons-material/Language';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useFetchUser } from '../hooks/user.hooks';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '../stores/UserStore';
import DepartmentChart from './BuildingOverview';
import { useAuth } from '../helper/AuthContext';
import { SubscriptionType, UserRoleType } from '../types/types';
import { api } from '../utils/api';
import { clearAllData } from '../utils/indexedDB';
import { useFetchRentalIncome } from '../hooks/rental.hooks';
import MonthlyChart from './MonthlyRevenue';
import YearlyChart from './YearlyRevenue';

const OverviewPage = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userRoleType, subscriptionType } = useAuth();
  const [greeting, setGreeting] = useState<string>('');
  const [modalMessages, setModalMessages] = useState<string[]>([]);
  const { data: user } = useFetchUser();
  useFetchRentalIncome();

  useEffect(() => {
    if (user) {
      setGreeting(
        getGreeting(
          user.Fullname?.split(' ')[0] ?? user.Username ?? 'Guest',
          new Date().getHours()
        )
      );
      checkUserInformation(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getGreeting = (fullname: string, hour: number): string => {
    if (hour >= 5 && hour < 12) return `${t('good_morning')}, ${fullname}`;
    if (hour >= 12 && hour < 18) return `${t('good_afternoon')}, ${fullname}`;
    return `${t('good_evening')}, ${fullname}`;
  };

  const handleCloseModal = () => {
    useUserStore.getState().setMessageDismissed(true);
    setModalMessages([]);
  };

  const checkUserInformation = (user: any) => {
    const { isMessageDismissed } = useUserStore.getState();

    if (isMessageDismissed) {
      return;
    }

    const messages = [];

    if (!user?.Fullname || !user?.Country) {
      messages.push(t('profile_update_message'));
    }

    if (user?.VerificationExpiryDate) {
      const expiryDate = format(
        new Date(user.VerificationExpiryDate),
        'dd MMM yyyy'
      );
      messages.push(
        t('email_verification_message', {
          email: user.Email,
          expiryDate,
        })
      );
    }

    if (messages.length > 0) {
      setModalMessages(messages);
    }
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleLanguageChange = (lang: string) => {
    localStorage.setItem('i18nextLng', lang);

    i18n.changeLanguage(lang).then(() => {
      clearAllData();
      api.updateUserLanguage(lang).catch((error) => {
        toast.error(error.message);
      });

      // Delay reload to ensure state update
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });

    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* Language Switcher */}
      <Box
        sx={{ position: 'absolute', top: '60px', right: '16px', zIndex: 1000 }}
      >
        <IconButton onClick={handleLanguageClick}>
          <Language />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleLanguageChange('en')}>
            {t('language.english')}
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange('fr')}>
            {t('language.french')}
          </MenuItem>
        </Menu>
      </Box>

      {/* Main Content */}

      <Box sx={{ mt: 6 }}>
        <Typography
          variant="h6"
          mb={3}
          sx={{
            position: 'absolute',
            left: 0,
            top: '80px',
            fontSize: '1.0rem',
            paddingLeft: '16px',
          }}
        >
          {greeting}
        </Typography>
        {userRoleType === UserRoleType.Landlord &&
          subscriptionType === SubscriptionType.Premium && (
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={6}>
                <MonthlyChart />
              </Grid>
              <Grid item xs={12} md={6}>
                <YearlyChart />
              </Grid>
            </Grid>
          )}

        <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
          <DepartmentChart />
        </Box>
      </Box>

      <Modal
        open={modalMessages.length > 0}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {t('updateProfile')}
          </Typography>
          {modalMessages.map((message, index) => (
            <Typography key={index} sx={{ mt: 2 }}>
              {index + 1}: {message}
            </Typography>
          ))}
          <Button onClick={handleCloseModal} variant="contained" sx={{ mt: 2 }}>
            Do not show again
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default OverviewPage;
