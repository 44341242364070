import { create } from 'zustand';
import { Room } from '../utils/interfaces';

interface RoomStore {
  rooms: Room[] | null;
  setRooms: (rooms: Room[]) => void;
  isRoomsLoading: boolean;
  setLoading: (isRoomsLoading: boolean) => void;
}

export const useRoomStore = create<RoomStore>((set) => ({
  rooms: null,
  isRoomsLoading: false,

  setRooms: (rooms: Room[]) => set({ rooms }),
  setLoading: (isRoomsLoading: boolean) => set({ isRoomsLoading }),
}));
